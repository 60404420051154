import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "hideable" ]

  showTargets() {
    this.hideableTargets.forEach(el => {
      el.hidden = false
      el.classList.add('hidden');
    });
  }

  hideTargets() {
    this.hideableTargets.forEach(el => {
      el.hidden = true
      el.classList.remove('hidden');
    });
  }

  toggleTargets(event) {
    this.hideableTargets.forEach((el) => {
      el.hidden = !el.hidden;
      el.classList.toggle('hidden');
      event.target.classList.toggle('rotate-180');
    });
  }
}
